import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  homePage: [],
  aboutPage: [],
  apps: [],
  contactUs: [],
  forTravellerPage: [],
  forGuidePage: [],
  contactUsPage: [],
  privacyPage: [],
  termsPage: [],
  header: [],
  footer: [],
  waitListPage: [],
  landingPage: [],
  faqPage: [],
  ourTeamPage: [],
  publishLink: [],
};
const ContentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    HOMEPAGE: (state, action) => {
      return {
        ...state,
        homePage: action.payload,
      };
    },
    ABOUTPAGE: (state, action) => {
      return {
        ...state,
        aboutPage: action.payload,
      };
    },
    APPS: (state, action) => {
      return {
        ...state,
        apps: action.payload,
      };
    },
    CONTACT_US: (state, action) => {
      return {
        ...state,
        contactUs: action.payload,
      };
    },
    FOR_TRAVELLERS_PAGE: (state, action) => {
      return {
        ...state,
        forTravellerPage: action.payload,
      };
    },
    FOR_GUIDE_PAGE: (state, action) => {
      return {
        ...state,
        forGuidePage: action.payload,
      };
    },
    CONTACT_US_PAGE: (state, action) => {
      return {
        ...state,
        contactUsPage: action.payload,
      };
    },
    PRIVACY_PAGE: (state, action) => {
      return {
        ...state,
        privacyPage: action.payload,
      };
    },
    TERMS_PAGE: (state, action) => {
      return {
        ...state,
        termsPage: action.payload,
      };
    },
    HEADER: (state, action) => {
      return {
        ...state,
        header: action.payload,
      };
    },
    FOOTER: (state, action) => {
      return {
        ...state,
        footer: action.payload,
      };
    },
    WAIT_LIST_PAGE: (state, action) => {
      return {
        ...state,
        waitListPage: action.payload,
      };
    },
    LANDING_PAGE: (state, action) => {
      return {
        ...state,
        landingPage: action.payload,
      };
    },
    FAQ_PAGE: (state, action) => {
      return {
        ...state,
        faqPage: action.payload,
      };
    },
    OUR_TEAM_PAGE: (state, action) => {
      return {
        ...state,
        ourTeamPage: action.payload,
      };
    },
    OUR_TEAM_PAGE_PUBLISH: (state, action) => {
      return {
        ...state,
        publishLink: action.payload,
      };
    },
  },
});
export const {
  HOMEPAGE,
  ABOUTPAGE,
  APPS,
  CONTACT_US,
  FOR_TRAVELLERS_PAGE,
  FOR_GUIDE_PAGE,
  CONTACT_US_PAGE,
  PRIVACY_PAGE,
  TERMS_PAGE,
  HEADER,
  FOOTER,
  WAIT_LIST_PAGE,
  LANDING_PAGE,
  FAQ_PAGE,
  OUR_TEAM_PAGE,
  OUR_TEAM_PAGE_PUBLISH,
} = ContentSlice.actions;
export default ContentSlice.reducer;
