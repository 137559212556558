import React, { useEffect } from "react";
import mail_sent from "../../../assets/images/mail-sent.svg";
import "../LoginSignup.css";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";

const EmailVerificationPopup = (props) => {
  const navigate = useNavigate();
  const handleClose = () => {
    props.onHide();
    navigate("/login");
  };
  return (
    <div
      className={
        props.show === true ? "mail-sent-popup active" : "mail-sent-popup"
      }
    >
      <div className="main-sent-wrapper zindex-up">
        <button
          type="button"
          className="btn-close"
          onClick={handleClose}
        ></button>
        <img src={mail_sent} alt="" />
        {props?.msgType === "html" ? (
          <div>{parse(props.message)}</div>
        ) : (
          <p>{`${
            props.message ||
            "Email verification link sent; confirm via email and text. Check it out"
          }`}</p>
        )}
      </div>
      <div className="popup-overlay" onClick={props.onHide}></div>
    </div>
  );
};

export default EmailVerificationPopup;
