import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import { postApi } from "../../../config/apiFunctions";
import { useToast } from "@chakra-ui/react";
import { create_activity_request } from "../../../config/endpoints";

function ActivityFormModal(props) {
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();

  const [btndisable, setBtnDisable] = useState(false);

  const onSubmit = (data) => {
    setBtnDisable(true);
    const obj = {
      email: props?.Userdata?.email,
      phone: props?.Userdata?.phone,
      name: data.name,
      description: data.description,
      user_id: props?.Userdata?.id
    };
    postApi(`${create_activity_request}`, obj)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
        });
        setBtnDisable(false);
        props.onHide();
        reset();
      })
      .catch((err) => {
        console.log(err);
        // toast({
        //     title: err.response.data.message,
        //     status: "success",
        //     duration: 30000,
        //     isClosable: true,
        //     position: "top-right",
        //   });
        setBtnDisable(false);
      });
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="new-modal-design"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body">
        <div className="d-flex justify-content-between align-items-center mb-4 pb-3 border-bottom">
          <h4 className="f-700 mb-0">Request New Activity</h4>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.onHide()}
          ></button>
        </div>
        <div className="mb-4">
          <form
            className="create-profile-form mt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row gy-3 gy-lg-4">
              <div className="col-md-6">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Email
                </label>
                <input
                  type="email"
                  disabled
                  name="email"
                  rows="3"
                  placeholder="you@company.com"
                  defaultValue={props?.Userdata?.email}
                  {...register("email")}
                  className={`form-control theme-controls ${
                    errors.email ? "is-invalid" : ""
                  }`}
                />
              </div>
              <div className="col-md-6">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Contact Number
                </label>
                <input
                  type="number"
                  disabled
                  name="phone"
                  rows="3"
                  placeholder="Enter your contact number"
                  defaultValue={props?.Userdata?.phone}
                  {...register("phone")}
                  className={`form-control theme-controls ${
                    errors.phone ? "is-invalid" : ""
                  }`}
                />
              </div>

              <div className="col-lg-12">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  placeholder="Enter activity name here"
                  {...register("name")}
                  className={`form-control theme-controls ${
                    errors.name ? "is-invalid" : ""
                  }`}
                ></input>
                <div className="invalid-feedback">{errors.name?.message}</div>
              </div>

              <div className="col-lg-12">
                <label
                  htmlFor=""
                  className="form-label theme-label color__paragraph"
                >
                  Description
                </label>
                <textarea
                  name="description"
                  rows="3"
                  placeholder="Enter description here"
                  {...register("description")}
                  className={`form-control theme-controls ${
                    errors.description ? "is-invalid" : ""
                  }`}
                ></textarea>
                <div className="invalid-feedback">
                  {errors.description?.message}
                </div>
              </div>
              <div className="col-lg-12">
                {btndisable === true ? (
                  <button
                    type="submit"
                    className="button-primary w-100 next-step create-profile-btn"
                  >
                    <ThreeDots
                      height="20"
                      width="80"
                      radius="9"
                      color="white"
                      ariaLabel="three-dots-loading"
                      wrapperStyle={{}}
                      wrapperClassName=""
                      visible={true}
                    />
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={btndisable}
                    className="button-primary create-profile-btn w-100"
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

export default ActivityFormModal;

const Schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
});
