import React, { useEffect, useState } from "react";
import "../footer/Footer.css";
// import footer_logo from "../../../assets/images/siestalogo2.svg";
import Appstore from "../../../assets/images/app_store1.svg";
import Appstore2 from "../../../assets/images/app_store2.svg";
import facebook from "../../../assets/images/facebook.svg";
import instagram from "../../../assets/images/instagram.svg";
import linkedin from "../../../assets/images/likdine.svg";
import twitter from "../../../assets/images/twitter.svg";
import pinterest from "../../../assets/images/pinterest.svg";
import reddit from "../../../assets/images/reddit.svg";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FOOTER } from "../../../redux/slices/content/ContentSlice";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
const Footer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const User = useSelector((state) => state.auth.SignupUser);
  const token = User?.access_token;
  const access_token = token === "undefined" ? undefined : token;
  const { footer: data } = useSelector((state) => state.content);
  const [footerLogoData, setFooterLogoData] = useState();
  const [footerContentData, setFooterContentData] = useState();
  const [footerLinksData, setFooterLinksData] = useState();
  const publishLink = useSelector((state) => state.content.publishLink);

  const getFooterData = () => {
    getApi(`${website_content}?key=HEADER_FOOTER`).then((res) => {
      dispatch(FOOTER(res?.data?.data));
    });
  };
  useEffect(() => {
    getFooterData();
  }, []);
  useEffect(() => {
    if (data) {
      const getFooterLogoData = data?.find((e) => e.section === "LOGO");
      setFooterLogoData(getFooterLogoData?.value);

      const getFooterContentData = data?.find((e) => e.section === "CONTENT");
      setFooterContentData(getFooterContentData?.value);

      const getFooterLinksData = data?.find(
        (e) => e.section === "SOCIAL_LINKS"
      );
      setFooterLinksData(getFooterLinksData?.value);
    }
  }, [data]);
  return (
    <footer>
      <div className="footer_home_page primary__bg">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="footer_left">
                <div className="footer_logo">
                  <Link to="/">
                    <img
                      src={
                        footerLogoData &&
                        footerLogoData?.footer?.upload_image_url
                      }
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="footer_content">
                  {footerContentData?.subtitle}
                </div>
              </div>
            </div>
            <div className="col-md-8 position-relative">
              <div className="get_app">
                <p>Coming Soon</p>
                {/* <p>Get the app</p>*/}
                <div className="footer_right d-flex gap-4">
                  <Link
                    to="/"
                    // to="https://apps.apple.com/us/app/siesta-travel/id6473368509"

                    target="_blank"
                  >
                    <img src={Appstore} alt="app_store" />
                  </Link>
                  <Link
                    // to="https://play.google.com/store/apps/details?id=com.app.siestaglobal"
                    to="/"
                    target="_blank"
                  >
                    <img src={Appstore2} alt="app_store" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* {location?.pathname !== "/join-localites" && ( */}
          <div className="footer_manu">
            <ul>
              <li>
                <Link to="/" className="text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about-us" className="text-white">
                  About Us
                </Link>
              </li>
              {(User.role_name === "TRAVELLER" || !access_token) && (
                <li>
                  <Link to="/traveler" className="text-white">
                    {/* Traveller */}
                    For Travelers
                  </Link>
                </li>
              )}
              {(User.role_name === "GUIDE" || !access_token) && (
                <li>
                  <Link to="/join-localites" className="text-white">
                    {/* Tourist Guide */}
                    {/* For Guides */}
                    For Localites
                  </Link>
                </li>
              )}
              <li>
                <Link to="/blog" className="text-white">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contact-us" className="text-white">
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/faq" className="text-white">
                  FAQ
                </Link>
              </li>
              {publishLink?.value?.publish_link && (
                <li>
                <Link to="/meet-our-team" className="text-white">
                  Meet Our Team
                </Link>
              </li>
              )}
              <li>
                <Link to="/privacy" className="text-white">
                  Privacy & Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* // )} */}
        </div>
      </div>
      <div className="footer_bottom1 primary__bg">
        <div className="container">
          <div className="footer_bottom">
            <div className="left_content">
              <div className="text-white m-0">
                {footerContentData?.copyright}
              </div>
            </div>
            <div className="right_side">
              <ul>
                <li>
                  <a href={footerLinksData && footerLinksData?.facebook}>
                    <img src={facebook} alt="social" />
                  </a>
                </li>
                <li>
                  <a href={footerLinksData && footerLinksData?.instagram}>
                    <img src={instagram} alt="social" />
                  </a>
                </li>
                <li>
                  <a href={footerLinksData && footerLinksData?.linkedin}>
                    <img src={linkedin} alt="social" />
                  </a>
                </li>
                <li>
                  <a href={footerLinksData && footerLinksData?.twitter}>
                    <img src={twitter} alt="social" />
                  </a>
                </li>
                <li>
                  <a href={footerLinksData && footerLinksData?.reddit}>
                    <img src={reddit} alt="social" />
                  </a>
                </li>
                <li>
                  <a href={footerLinksData && footerLinksData?.pinterest}>
                    <img src={pinterest} alt="social" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
