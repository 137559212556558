import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export default function JoinUs({ opportunityData }) {
  return (
    <section className="join_us">
      <div className="container spacer-y">
        <div className="row g-4 align-items-center justify-content-between">
          <div className="col-lg-6 ms-auto">
            <div className="join_us_wrapper">
              <h3 className="mb-3 pb-0">
                {opportunityData?.title}{" "}
                <span className="primary__color">
                  {opportunityData?.titleBlue}
                </span>
              </h3>
              <p>{parse(`${opportunityData?.description}`)}</p>
              <Link
                to="/localite/signup"
                // state={{ fromLangingPage: true }}
                className="button-secondary button-blue"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
