import React, { useEffect, useState } from "react";
import camera from "../../../../assets/images/camera.svg";
import upload from "../../../../assets/images/upload.svg";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { getApi, putApi } from "../../../../config/apiFunctions";
import {
  ProfileImage,
  Guide_Update_Profile,
  get_activities,
} from "../../../../config/endpoints/index";
import { useToast } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { PROFILE_PIC } from "../../../../redux/slices/profilePicture/ProfilePictureSlice";
import { phoneRegExp } from "../../../../config/common/common";
import PhoneInput from "react-phone-input-2";
import { ThreeDots } from "react-loader-spinner";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import Commision from "../../../../dashboard/sections/home/components/pages/settingPage/Commision";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ZipCode from "./ZipCode";
import Multiselect from "multiselect-react-dropdown";
import ActivityFormModal from "../../../popup/activityFormModal/ActivityFormModal";

const CreateGuideProfile = () => {
  const dispatch = useDispatch();
  const [btndisable, setBtnDisable] = useState(false);
  const navigate = useNavigate();
  const toast = useToast();
  const [file, setFile] = useState();
  const [guideCheck, setGuideCheck] = useState(false);
  const [multipleImages, setMultipleImages] = useState([]);
  const [addImages, setAddImages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countryId, setCountryId] = useState();
  const [state, setStateSelect] = useState([]);
  const [hostYear, setHostYear] = useState();
  const [hostMonth, setHostMonth] = useState();
  const [acitvityList, setActivityList] = useState();
  const [selectedActivity, setSelectedActivity] = useState([]);
  const [selectedActivityID, setSelectedActivityID] = useState([]);
  const [activityError, setActivityError] = useState(false);
  const [showActivityForm, setShowActivityForm] = useState(false);

  const [getCityLoader, setGetLoader] = useState({
    countries: false,
    state: false,
    city: false,
  });

  const [getAddressData, setGetAddressData] = useState({
    lat: "",
    long: "",
    countryZip: "United States",
    stateZip: "",
    cityZip: "",
  });
  // for zip code
  // let countryZip = null;
  // let stateZip = null;
  // let cityZip = null;
  // let lat;
  // let long;
  // const [scriptLoaded, setScriptLoaded] = useState(false);
  // const [address, setAddress] = useState();
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACE_KEY}&libraries=places`;
  //   script.async = true;
  //   script.defer = true;
  //   script.onload = () => {
  //     setScriptLoaded(true);
  //   };
  //   document.head.appendChild(script);
  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, []);

  // const handleChangePlace = (e) => {
  //   setAddress(e);
  // };
  // const handleSelectPlace = (address) => {
  //   geocodeByAddress(address)
  //     .then((results) => {
  //       getLatLng(results[0]);

  //       const addressComponents = results[0].address_components;
  //       console.log("addressComponents", addressComponents);

  //       addressComponents.forEach((component) => {
  //         if (component.types.includes("country")) {
  //           countryZip = component.long_name;
  //         }
  //         if (component.types.includes("administrative_area_level_1")) {
  //           stateZip = component.long_name;
  //         }
  //         if (
  //           component.types.includes("administrative_area_level_3") ||
  //           component.types.includes("postal_town")
  //         ) {
  //           cityZip = component.long_name;
  //         }
  //       });
  //       lat = results[0].geometry.location.lat();
  //       long = results[0].geometry.location.lng();

  //       setGetAddressData({
  //         ...getAddressData,
  //         lat: lat,
  //         long: long,
  //         countryZip: countryZip,
  //         stateZip: stateZip,
  //         cityZip: cityZip,
  //       });
  //     })
  //     .then((latLng) => console.log("Success", latLng))
  //     .catch((error) => console.error("Error", error));
  // };
  // zip code end
  const [city, setCity] = useState([]);

  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const Userdata = useSelector((state) => state.auth.SignupUser);
  const Profile_Picture = useSelector((state) => state.profilePic.profilePic);
  const device_token = useSelector((state) => state.auth.device_token);

  const pronouns = [{ name: "He/Him" }, { name: "She/Her" }];

  useEffect(() => {
    if (Profile_Picture.length !== 0) {
      setValue("pp", Profile_Picture.toString());
      trigger("pp");
    }
  }, [Profile_Picture]);
  function handleChange(e) {
    if (guideCheck === true) {
      const formdata = new FormData();
      formdata.append("profile_picture", e.target.files[0]);
      formdata.append("id", Userdata.id);
      formdata.append("email", Userdata.email);
      putApi(ProfileImage, formdata)
        .then((res) => {
          dispatch(PROFILE_PIC(res.data.data.image_url));
          setFile(res.data.data.image_url);
          toast({
            title: res.data.message,
            status: "success",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch((err) => {
          setBtnDisable(false);
          toast({
            title: err.response.data.message,
            status: "error",
            duration: 30000,
            isClosable: true,
            position: "top-right",
          });
        });
    } else {
      toast({
        title: "Please agree to the background check for Imerzn enrollment.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
      });
    }
  }

  // const [contactDetails, setContatDetails] = useState({
  //   phone: "",
  //   isoCode: "+1",
  //   dialCode: "us",
  // });
  // const onContactNoChange = (value, country, e, formattedValue) => {
  //   const { dialCode: country_code, countryCode: country_iso_code } = country;
  //   const _phone = value.slice(country_code.length);
  //   setContatDetails({
  //     ...contactDetails,
  //     phone: _phone,
  //     isoCode: country_iso_code,
  //     dialCode: country_code,
  //   });
  //   setValue("phone", _phone);
  //   trigger("phone");
  // };

  // Functions to preview multiple images
  const changeMultipleFiles = (e) => {
    if (e.target.files) {
      const imageArray = Array.from(e.target.files).map((file, index) => {
        const arr = URL.createObjectURL(file);
        setAddImages((prev) => [...prev, e.target.files[index]]);
        setMultipleImages((prevImages) => prevImages.concat(arr));
      });
    }
  };

  const getActivitiesList = () => {
    getApi(get_activities).then((res) => {
      if (res.status === 200) {
        setActivityList(res?.data?.data);
      }
    });
  };

  const options = acitvityList?.rows;

  const [selectedValue, setSelectedValue] = useState([]);

  const onSelect = (selectedList, selectedItem) => {
    setSelectedValue(selectedList);
    setSelectedActivity(selectedList?.map((item) => item.name));
    setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedValue(selectedList);
    setSelectedActivity(selectedList?.map((item) => item.name));
    setSelectedActivityID(selectedList?.map((item) => item.id));
  };

  const onSubmit = (e) => {
    if (guideCheck) {
      setTimeout(() => {
        setBtnDisable(true);
      }, 100);
      // return false;

      if (selectedActivityID?.length === 0 || !selectedActivityID) {
        setBtnDisable(false);
        setActivityError(true);
      } else {
        const formData = new FormData();
        // for (let i = 0; i < e.id_proof.length; i++) {
        //   formData.append("id_proof", e.id_proof[i]);
        // }
        for (let i = 0; i < addImages.length; i++) {
          formData.append("id_proof", addImages[i]);
        }
        formData.append("bio", e.bio);
        formData.append("pincode", e.pincode);
        // formData.append("pincode", address);
        // formData.append("price", e.price);
        formData.append("currency", "USD");
        formData.append("country", e.country);
        formData.append("state", e.state);
        formData.append("city", e.city);
        formData.append("phone", Userdata?.phone);
        for (let i = 0; i < selectedActivityID.length; i++) {
          formData.append("activities", selectedActivityID[i]);
        }
        if (e.pronouns) {
          formData.append("pronouns", e.pronouns);
        }
        formData.append("host_since_years", hostYear ? `${hostYear}` : 0);
        formData.append("host_since_months", hostMonth ? `${hostMonth}` : 0);
        // formData.append("country_code", `+${contactDetails.dialCode}`);
        // formData.append("country_code_iso", contactDetails.isoCode);
        formData.append("email", Userdata.email);
        formData.append("id", Userdata.id);
        formData.append("application_type", 1);
        // formData.append("longitude", getAddressData.long);
        // formData.append("latitude", getAddressData.lat);

        putApi(Guide_Update_Profile, formData)
          .then((res) => {
            if (res.status === 200) {
              toast({
                title: res.data.message,
                status: "success",
                duration: 30000,
                isClosable: true,
                position: "top-right",
                onCloseComplete: () => setBtnDisable(false),
              });
              reset();
              setFile(null);
              setMultipleImages([]);
              navigate("/login");
            }
          })
          .catch((err) => {
            toast({
              title: err.response.data.message,
              status: "error",
              duration: 30000,
              isClosable: true,
              position: "top-right",
              onCloseComplete: () => setBtnDisable(false),
            });
          });
      }
    } else {
      toast({
        title: "Please agree to the background check for Imerzn enrollment.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
      });
    }
  };
  const handleRemove = (i, isNewIndexvalue = -1) => {
    if (isNewIndexvalue) {
      const _UPLOAD_IMG_INDEX = i - (multipleImages.length - addImages.length);
      setAddImages(
        addImages.filter((item, _index) => _index !== _UPLOAD_IMG_INDEX)
      );
    }

    setMultipleImages((current) => {
      return current.filter((item, index) => index !== i);
    });
  };
  //disable scroll on input type number
  const handleChangee = (e) => {
    const numberInput = document.getElementById("numberInput2");
    numberInput?.addEventListener("wheel", (e) => {
      e.preventDefault();
    });

    if (e.target.value) {
      setValue("price", e.target.value);
      trigger("price");
      const numberInput = document.getElementById("numberInput");
      numberInput?.addEventListener("wheel", (event) => {
        event.preventDefault();
      });
    }
    trigger("price");
  };

  const isSpecialChar = (key) => {
    // Regular expression to match special characters
    const specialChars = /[~`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    return specialChars.test(key);
  };

  const preventPaste = (event) => {
    event.preventDefault();
  };

  const checkhandle = (e) => {
    setGuideCheck(e);
  };
  const handleGuideProfileCheckbox = () => {
    toast({
      title: "Please agree to the background check for Imerzn enrollment.",
      status: "error",
      duration: 30000,
      isClosable: true,
      position: "top-right",
      onCloseComplete: () => setBtnDisable(false),
    });
  };

  // on select zipcode
  // const getAddressWithZip = async (data) => {
  //   const country = countries.find(
  //     (country) => country.name === data?.countryZip
  //   );
  //   setValue("country", country?.name);
  //   setCountryId(country.id);
  //   await GetState(country.id).then(async (state) => {
  //     const selectedState = state.find(
  //       (state) => state.name === data?.stateZip
  //     );
  //     setStateSelect(state);
  //     await GetCity(country.id, selectedState?.id).then((result) => {
  //       const selectedCity = result.find((city) => city.name === data?.cityZip);
  //       setCity(result);
  //       setTimeout(() => {
  //         if (selectedCity) {
  //           setValue("city", selectedCity?.name);
  //         } else {
  //           setValue("city", result[0]?.name);
  //         }
  //       }, 2000);
  //       setValue("state", selectedState?.name);
  //     });
  //   });
  // };

  // set country ,city ,state
  const handleAddress = async (event, type) => {
    switch (type) {
      case "country":
        {
          setGetLoader({ ...getCityLoader, ["state"]: true });
          const { value } = event.target;
          const country = countries.find((country) => country.name === value);
          setValue("country", country?.name);
          setCountryId(country.id);
          await GetState(country.id).then(async (state) => {
            setValue("state", state[0]?.name);
            setStateSelect(state);
            await GetCity(country.id, state[0]?.id).then((result) => {
              setValue("city", result[0]?.name);
              setCity(result);
            });
          });
          setGetLoader({ ...getCityLoader, ["state"]: false });
        }
        break;

      case "state":
        {
          setGetLoader({ ...getCityLoader, ["city"]: true });
          const { value } = event.target;
          const selectedState = state.find((state) => state.name === value);
          await GetCity(countryId, selectedState.id).then((result) => {
            setValue("city", result[0]?.name);
            setCity(result);
          });
          setGetLoader({ ...getCityLoader, ["city"]: false });
        }
        break;

      default:
        getDefaultAddress();
        break;
    }
  };

  // get default address
  const getDefaultAddress = async () => {
    await GetCountries().then(async (country) => {
      const filterArray = country.find(
        (e) => e.name === getAddressData.countryZip
      );
      setValue("country", filterArray.name);
      setCountryId(filterArray.id);
      setCountries(country);
      await GetState(filterArray.id).then(async (state) => {
        setValue("state", state[0].name);
        setStateSelect(state);
        await GetCity(filterArray.id, state[0].id).then((result) => {
          setValue("city", result[0].name);
          setCity(result);
        });
      });
    });
  };

  const incrementHostSince = () => {
    setHostYear((prevYear) => prevYear + 1);
    setValue("hostSinceYear", hostYear + 1);
  };

  const incrementHostSinceMonth = () => {
    if (hostMonth < 11) {
      setHostMonth((prevMonth) => prevMonth + 1);
      setValue("hostSinceMonth", hostMonth + 1);
    }
  };
  const decrementHostSince = () => {
    if (hostYear > 0) {
      setHostYear((prevYear) => prevYear - 1);
      setValue("hostSinceYear", hostYear - 1);
    }
  };
  const decrementHostSinceMonth = () => {
    if (hostMonth > 0) {
      setHostMonth((prevMonth) => prevMonth - 1);
      setValue("hostSinceMonth", hostMonth - 1);
    }
  };
  const handleInputChangeYear = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setHostYear(value);
    }
  };
  const handleInputChangeMonth = (event) => {
    const value = parseInt(event.target.value, 10);
    if (!isNaN(value)) {
      setHostMonth(value);
    }
  };

  useEffect(() => {
    getDefaultAddress();
    getActivitiesList();
  }, []);

  // useEffect(() => {
  //   getAddressWithZip(getAddressData);
  // }, [getAddressData]);

  useEffect(() => {
    if (selectedActivityID?.length > 0) {
      setActivityError(false);
    }
  }, [selectedActivityID]);

  return (
    <>
      <section className="login-signup-wrapper create-profile-page signup-page">
        <div className="container-fluid px-0 spacer-y">
          <div className="row gy-4 gy-lg-0 gx-0 zindex-up">
            <div className="col-lg-6 pe-lg-5 order-2 order-lg-1 spacing-left">
              <h2 className="pb-0 mb-3">Create Your Profile</h2>
              <p>Create your profile to see next advantures.</p>
              <p className="text-sm">
                <strong>Note:</strong> Enhance your experience with us! Please
                update your profile information by filling out our quick profile
                update form. Once completed, you'll be redirected to your
                dashboard, where you can unlock all the exciting features and
                benefits of our platform.
              </p>
              <div className="col-lg-12">
                <div className="checkbox-wrap">
                  <label className="checkbox-container">
                    <input
                      type="checkbox"
                      onChange={(e) => checkhandle(e.target.checked)}
                    />
                    <div className="checkmark"></div>
                  </label>
                  <p className="mb-0">
                    I understand that my enrollment as a localite with Imerzn is
                    dependent on a successful background check run by a 3rd
                    party
                  </p>
                </div>
              </div>
              <div className="create-profile-wrapper mt-4">
                <div className="col-lg-12">
                  <div className="profile-pic-blk">
                    <label htmlFor="profile-pic" className="profile-image">
                      {guideCheck === true ? (
                        <input
                          type="file"
                          name="profile-pic"
                          className="profile-pic"
                          id="profile-pic"
                          onChange={handleChange}
                          accept="image/*"
                        />
                      ) : (
                        <>
                          <input
                            type="button"
                            id="profile-pic"
                            className="profile-pic"
                            onClick={handleGuideProfileCheckbox}
                          />
                        </>
                      )}

                      <img
                        src={
                          Profile_Picture?.length !== 0
                            ? Profile_Picture
                            : Userdata.profile_img_url
                        }
                        alt=""
                      />
                    </label>
                    <div className="camera-icon">
                      <img src={camera} alt="" />
                    </div>
                  </div>
                  <label
                    htmlFor=""
                    className="form-label theme-label d-block mb-0 color__paragraph "
                  >
                    Upload Profile Photo
                  </label>
                  <div className="text-danger" style={{ fontSize: ".875em" }}>
                    {errors.pp?.message}
                  </div>
                </div>
                <form
                  className="create-profile-form mt-4"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <input type="hidden" {...register("name")} name="pp" />

                  <div className="row gy-3 gy-lg-4">
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Host Since
                      </label>
                      <div className="row g-2">
                        <div className="col-lg-6">
                          <div className="position-relative">
                            <input
                              id="numberInput2"
                              type="number"
                              name="hostSinceYear"
                              placeholder="0"
                              {...register("hostSinceYear", {
                                onChange: handleInputChangeYear,
                              })}
                              className={`form-control theme-controls ${
                                errors.hostSinceYear ? "is-invalid" : ""
                              }`}
                              maxLength={10}
                              onPaste={(event) => preventPaste(event)}
                            />
                            <div className="invalid-feedback">
                              {errors?.hostSinceYear?.message}
                            </div>
                            <span className="placeholder__text host-button">
                              Years
                            </span>
                            <div
                              onClick={decrementHostSince}
                              type="button"
                              className="host-button decrement"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </div>
                            <div
                              onClick={incrementHostSince}
                              type="button"
                              className="host-button increment"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="position-relative">
                            <input
                              id="numberInput2"
                              type="number"
                              name="hostSinceMonth"
                              placeholder="0"
                              {...register("hostSinceMonth", {
                                onChange: handleInputChangeMonth,
                              })}
                              className={`form-control theme-controls ${
                                errors.hostSinceMonth ? "is-invalid" : ""
                              }`}
                              onPaste={(event) => preventPaste(event)}
                            />
                            <div className="invalid-feedback">
                              {errors?.hostSinceMonth?.message}
                            </div>
                            <span className="placeholder__text host-button">
                              Months
                            </span>
                            <div
                              onClick={decrementHostSinceMonth}
                              type="button"
                              className="host-button decrement"
                            >
                              <i className="fa-solid fa-minus"></i>
                            </div>
                            <div
                              onClick={incrementHostSinceMonth}
                              type="button"
                              className="host-button increment"
                            >
                              <i className="fa-solid fa-plus"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Pronouns(optional)
                      </label>

                      <div className="position-relative">
                        <select
                          {...register("pronouns")}
                          defaultValue=""
                          type="text"
                          className={`form-control theme-controls ${
                            errors.pronouns ? "is-invalid" : ""
                          }`}
                        >
                          <option hidden value="" disabled>
                            Select
                          </option>
                          {pronouns.map((item, index) => {
                            return (
                              <option value={item.name} key={index}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                        <div className="host-button increment">
                          <i className="fa-solid fa-angle-down"></i>
                        </div>

                        <div className="invalid-feedback">
                          {errors.pronouns?.message}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Bio
                      </label>
                      <textarea
                        name="bio"
                        rows="3"
                        placeholder="Enter your bio"
                        {...register("bio")}
                        className={`form-control theme-controls ${
                          errors.bio ? "is-invalid" : ""
                        }`}
                      ></textarea>
                      <div className="invalid-feedback">
                        {errors.bio?.message}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        disabled
                        name="email"
                        rows="3"
                        placeholder="you@company.com"
                        defaultValue={Userdata?.email}
                        {...register("email")}
                        className={`form-control theme-controls ${
                          errors.email ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                    {/* <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph m-0"
                    >
                      Price (Hourly Rate)
                    </label>
                    <p style={{ fontSize: "12px" }} className="m-0">
                      (Imerzn will deduct <Commision /> commission)
                    </p>
                    <input
                      id="numberInput2"
                      type="number"
                      name="price"
                      placeholder="Enter per hour price"
                      // {...register("price")}
                      //  onChange={handleChangee}
                      {...register("price", {
                        onChange: handleChangee,
                      })}
                      className={`form-control theme-controls ${
                        errors.price ? "is-invalid" : ""
                      }`}
                      onKeyDown={(evt) =>
                        ["e", "E", "+", "-", "."].includes(evt.key) &&
                        evt.preventDefault()
                      }
                      onPaste={(event) => preventPaste(event)}
                    />
                    <div className="invalid-feedback">
                      {errors.price?.message}
                    </div>
                  </div> */}
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Contact Number
                      </label>
                      {/* <PhoneInput
                      country="us"
                      disabled
                      value={`${contactDetails.dialCode}${contactDetails.phone}`}
                      placeholder="Enter contact number"
                      countryCodeEditable={false}
                      enableSearch={true}
                      onChange={(value, country, e, formattedValue) =>
                        onContactNoChange(value, country, e, formattedValue)
                      }
                      sx={{ width: "100%" }}
                      isValid={() => (!!errors.phone ? "" : true)}
                    /> */}
                      <input
                        type="number"
                        disabled
                        name="phone"
                        rows="3"
                        placeholder="Enter your contact number"
                        defaultValue={Userdata?.phone}
                        {...register("phone")}
                        className={`form-control theme-controls ${
                          errors.phone ? "is-invalid" : ""
                        }`}
                      />
                      <div className="invalid-feedback">
                        {errors.phone?.message}
                      </div>
                    </div>

                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between">
                        <label
                          htmlFor=""
                          className="form-label theme-label color__paragraph"
                        >
                          Activities
                        </label>
                        <p
                          onClick={() => setShowActivityForm(true)}
                          role="button"
                          className="primary__color m-0"
                        >
                          Request new activity
                        </p>
                      </div>
                      <Multiselect
                        options={options}
                        selectedValues={selectedValue}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        displayValue="name"
                        showCheckbox={true}
                      />
                      {activityError && (
                        <div className="location_error">
                          Activity is required
                        </div>
                      )}
                    </div>
                    {/* <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Zipcode
                    </label>

                    {scriptLoaded && (
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleChangePlace}
                        onSelect={handleSelectPlace}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: "Zip code",
                                className: "location-search-input form-control theme-controls",
                                type:"number"
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map((suggestion) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    )}
                    
                  </div> */}
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        Country
                      </label>
                      {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("country")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={30}
                    /> */}
                      {countries.length > 0 && (
                        <select
                          {...register("country")}
                          name="country"
                          // defaultValue={"United States"}
                          defaultValue={getAddressData.countryZip}
                          onChange={(e) => handleAddress(e, "country")}
                          className={`form-control theme-controls ${
                            errors.country ? "is-invalid" : ""
                          }`}
                        >
                          {getCityLoader.country && (
                            <option value={""}>Loading...</option>
                          )}

                          {countries.length > 0 &&
                            countries.map((country, index) => {
                              return (
                                <option value={country.name} key={index}>
                                  {country.name}
                                </option>
                              );
                            })}
                        </select>
                      )}
                      <div className="invalid-feedback">
                        {errors.country?.message}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        State
                      </label>
                      {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("state")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={30}
                    /> */}
                      {state.length > 0 ? (
                        <select
                          {...register("state")}
                          type="text"
                          defaultValue={getAddressData.stateZip}
                          onChange={(e) => handleAddress(e, "state")}
                          className={`form-control theme-controls ${
                            errors.state ? "is-invalid" : ""
                          }`}
                        >
                          {getCityLoader.state && (
                            <option value={""}>Loading...</option>
                          )}
                          {state.length > 0 &&
                            state.map((state, index) => {
                              return (
                                <option value={state.name} key={index}>
                                  {state.name}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <input
                          name="state"
                          placeholder="State"
                          {...register("state")}
                          className={`form-control theme-controls ${
                            errors.state ? "is-invalid" : ""
                          }`}
                        />
                      )}
                      <div className="invalid-feedback">
                        {errors.state?.message}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph"
                      >
                        City
                      </label>
                      {/* <input
                      type="text"
                      name="country"
                      placeholder="Enter country/city"
                      {...register("city")}
                      className={`form-control theme-controls ${
                        errors.country ? "is-invalid" : ""
                      }`}
                      maxLength={30}
                    /> */}
                      {city.length > 0 ? (
                        <select
                          {...register("city")}
                          type="text"
                          defaultValue={getAddressData.cityZip}
                          className={`form-control theme-controls ${
                            errors.city ? "is-invalid" : ""
                          }`}
                        >
                          {getCityLoader.city && (
                            <option value={""}>Loading...</option>
                          )}
                          {city.length > 0 &&
                            city.map((city, index) => {
                              return (
                                <option value={city.name} key={index}>
                                  {city.name}
                                </option>
                              );
                            })}
                        </select>
                      ) : (
                        <input
                          name="city"
                          placeholder="City"
                          {...register("city")}
                          className={`form-control theme-controls ${
                            errors.city ? "is-invalid" : ""
                          }`}
                        />
                      )}
                      <div className="invalid-feedback">
                        {errors.city?.message}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <label
                        htmlFor=""
                        className="form-label theme-label color__paragraph m-0"
                      >
                        Zipcode
                      </label>

                      <input
                        id="numberInput2"
                        type="number"
                        name="pincode"
                        placeholder="Enter your zipcode"
                        {...register("pincode")}
                        className={`form-control theme-controls ${
                          errors.pincode ? "is-invalid" : ""
                        }`}
                        // onKeyDown={(evt) =>
                        //   ["e", "E", "+", "-", "."].includes(evt.key) &&
                        //   evt.preventDefault()
                        // }

                        maxLength={10}
                        onPaste={(event) => preventPaste(event)}
                      />
                      <div className="invalid-feedback">
                        {errors.pincode?.message}
                      </div>
                    </div>

                    {/* <div className="col-lg-12">
                    <label
                      htmlFor=""
                      className="form-label theme-label color__paragraph"
                    >
                      Upload Id Proof
                    </label>
                    <div htmlFor="" className="upload-proof-wrap d-block">
                      {multipleImages.length !== 0 ? (
                        <>
                          <input
                            multiple
                            type="file"
                            accept="image/*"
                            id="file-up"
                            name="id_proof"
                            {...register("id_proof", {
                              onChange: changeMultipleFiles,
                            })}
                            className={`form-control theme-controls d-none
                           ${multipleImages.length === 0 ? "is-invalid" : ""}`}
                          />
                        </>
                      ) : (
                        <>
                          <input
                            multiple
                            type="file"
                            id="file-up"
                            name="id_proof"
                            accept="image/*"
                            onChange={changeMultipleFiles}
                            className={`form-control theme-controls d-none
                             ${multipleImages.length == 0 ? "is-invalid" : ""}`}
                          />
                        </>
                      )}{" "}
                      <div className="invalid-feedback">
                        {errors.id_proof?.message}
                      </div>
                      <div
                        className="upload-proof form-control theme-controls"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        <label
                          htmlFor="file-up"
                          className="document-icon upload-trigger"
                        >
                          <img src={upload} className="upload-icon" alt="doc" />
                        </label>
                        {multipleImages.length === 0 ? (
                          <>
                            <span className="document-placeholder text-sm muted-text">
                              Image or doc will not be more than 2MB
                            </span>
                          </>
                        ) : (
                          <>
                            {multipleImages.map((image, i) => {
                              return (
                                <>
                                  <div className="document-icon" key={i}>
                                    <img
                                      name="id_proof"
                                      src={image}
                                      className="upload-icon"
                                      alt="doc"
                                    />
                                    <span
                                      type="button"
                                      onClick={() =>
                                        handleRemove(
                                          i,
                                          image.split(":")[0] === "blob" ? 1 : 0
                                        )
                                      }
                                      style={{
                                        top: "-12px",
                                        position: "absolute",
                                        left: "48px",
                                      }}
                                    >
                                      &times;
                                    </span>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        )}
                      </div>
                    </div>
                  </div> */}
                    <div className="col-lg-12">
                      {btndisable === true ? (
                        <button
                          type="submit"
                          className="button-primary w-100 next-step create-profile-btn"
                        >
                          <ThreeDots
                            height="20"
                            width="80"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button
                          type="submit"
                          disabled={btndisable}
                          className="button-primary create-profile-btn w-100"
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="aside-image"></div>
            </div>
          </div>
        </div>
        <div className="gradient-overlay hero"></div>
      </section>

      <ActivityFormModal
        show={showActivityForm}
        onHide={() => setShowActivityForm(false)}
        Userdata={Userdata}
      />
    </>
  );
};

export default CreateGuideProfile;

const Schema = Yup.object().shape({
  // phone: Yup.string()
  //   .required("Phone number is required")
  //   .matches(phoneRegExp, "Please enter valid mobile number"),
  country: Yup.string().required("Country is required"),
  state: Yup.string().required("State is required"),
  city: Yup.string(),
  bio: Yup.string().required("Bio is required"),
  pincode: Yup.string()
    .required("Zipcode is required")
    .matches(/^[a-zA-Z0-9]{5,10}$/, "Enter valid zipcode"),
  // price: Yup.string()
  //   .required("Price is required")
  //   .max(5, "Please enter valid price")
  //   .test("is-positive", "The price must be set above zero", (value) => {
  //     return parseFloat(value) > 0;
  //   }),
  // id_proof: Yup.mixed(),
  // id_proof: Yup.mixed().test("name", "Please Provide a File", (value) => {
  //   return value && value.name !== "";
  // }),
  pp: Yup.string().required("Profile picture is required"),
  pronouns: Yup.string(),
  hostSinceYear: Yup.string()
    .required("This field is required")
    .test("is-positive", "The year must be set above zero", (value) => {
      return parseFloat(value) > 0;
    }),
  hostSinceMonth: Yup.string()
    .required("This field is required")
    .test("not-above", "The months are not more than 11", (value) => {
      return parseFloat(value) <= 11;
    })
    .test("is-positive", "The month must be set zero or above", (value) => {
      return parseFloat(value) >= 0;
    }),
});
