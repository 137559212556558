import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { OUR_TEAM_PAGE } from "../../../redux/slices/content/ContentSlice";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

function OurTeam() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.ourTeamPage);
  const [loading, setLoading] = useState(false);


  const ourTeamPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=OUR_TEAM&section=OUR_TEAM`)
      .then((res) => {
        dispatch(OUR_TEAM_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    ourTeamPageData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="container spacer-y px-2 px-md-0 team__wrap">
          <h3 className="mb-5">{data?.value?.title}</h3>
          <div className="row g-4">
            {data?.value?.sections?.map((item, i) => (
              <div className="col-lg-6 col-md-6" key={i}>
                <div className="team__card">
                  <div className="team__image">
                    <img src={item?.image?.upload_image_url} alt={item?.name} />
                  </div>
                  <div className="team__content">
                    <h4 className="mb-1 d-block">{item?.name}</h4>
                    <p className="mb-3">{item?.title}</p>
                    {parse(`${item?.bio}`)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

export default OurTeam;
