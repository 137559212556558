import React, { useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch, useSelector } from "react-redux";
import { getApi } from "../../../config/apiFunctions";
import { website_content } from "../../../config/endpoints";
import { FAQ_PAGE } from "../../../redux/slices/content/ContentSlice";
import parse from "html-react-parser";
import { Oval } from "react-loader-spinner";

function FAQPage() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.content.faqPage);
  const [loading, setLoading] = useState(false);

  const faqPageData = () => {
    setLoading(true);
    getApi(`${website_content}?key=FAQ&section=FAQ`)
      .then((res) => {
        dispatch(FAQ_PAGE(res?.data?.data));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    faqPageData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <div className="container spacer-y px-2 px-md-0">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="toc-blk bg-white">
                <h2 className="mb-5 d-block">
                  {data?.value?.title}
                </h2>

                <Accordion defaultActiveKey="0" className="faq-accord" flush>
                  {data?.value?.sections.length > 0 &&
                    data?.value?.sections.map((item, i) => (
                      <Accordion.Item eventKey={`${i}`} key={i}>
                        <Accordion.Header className="mb-0 p-0">
                          <h5 className="mb-0">{item.question}</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          {parse(`${item.answer}`)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default FAQPage;
