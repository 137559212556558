import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getApi, postApi } from "../../../config/apiFunctions";
import {
  check_waiting_traveller,
  guide_logout,
  traveler_logout,
  waitListPage_content,
} from "../../../config/endpoints";
import { WAIT_LIST_PAGE } from "../../../redux/slices/content/ContentSlice";
import { Oval } from "react-loader-spinner";
import parse from "html-react-parser";
import "./index.css";
import logo from "../../../assets/images/Imerzn-Logo.svg";
import waitMobVec from "../../../assets/images/waiting_mob_vec.svg";
import waitWebVec from "../../../assets/images/waiting_web_vec.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  SIGNUP_USER_DETAILS,
  WAITING_STATUS,
} from "../../../redux/slices/auth/LoginSlice";
import LogOutModal from "../../../dashboard/LogOutModal";
import { persistor } from "../../../redux/store/store";

export default function WaitingPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const User = useSelector((state) => state.auth.SignupUser);
  const data = useSelector((state) => state.content.waitListPage);
  const [loading, setLoading] = useState(false);
  const [isSubmit, setisSubmit] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const logout = () => {
    setisSubmit(true);
    // persistor.purge();
    // navigate("/login");
    let api;
    if (User?.role_name === "GUIDE") {
      api = guide_logout;
    } else if (User?.role_name === "TRAVELLER") {
      api = traveler_logout;
    }
    postApi(api, {}, User.access_token)
      .then(async (res) => {
        setIsModalOpen(false);
        setisSubmit(false);
        // persistor.pause();
        await persistor.purge();
        dispatch(SIGNUP_USER_DETAILS(res.data.data));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/login");
        }, 50);
      })
      .catch(async (error) => {
        setisSubmit(false);
        // persistor.pause();
        await persistor.purge();
        dispatch(SIGNUP_USER_DETAILS({}));
        setTimeout(() => {
          document.body.classList.remove("dash");
          document.body.classList.remove("dashboard-module");
          document.body.classList.add("website");
          navigate("/login");
        }, 50);
      });
  };

  const waitingListData = () => {
    setLoading(true);
    getApi(
      `${waitListPage_content}?key=WAITING_LIST&section=WAIT_LIST`,
      {},
      User.access_token
    )
      .then((res) => {
        dispatch(WAIT_LIST_PAGE(res?.data?.data?.value));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  const checkingTravellerStatus = () => {
    const obj = {
      id: User.id,
      email: User.email,
    };
    if (User.id) {
      postApi(`${check_waiting_traveller}`, obj, User.access_token)
        .then((res) => {
          dispatch(WAITING_STATUS(res?.data?.data?.waiting_list));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (User) {
      checkingTravellerStatus();
    }
  }, [User]);

  useEffect(() => {
    waitingListData();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "auto",
            marginBottom: "auto",
            margin: "auto",
            height: "50vh",
            alignItems: "center",
          }}
        >
          <Oval
            height={80}
            width={80}
            color="#1f74ca"
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#1f74ca8f"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      ) : (
        <>
          <div
            className="d-flex justify-content-between align-items-center p-3 gap-4"         
          >
            <Link to="/">
              <img src={logo} className="waiting-logo" />
            </Link>
            <button
              onClick={() => setIsModalOpen(true)}
              className="button-primary"
            >
              Logout
            </button>
          </div>

          <div className="waiting_list_page">
            <img src={waitMobVec} className="mx-auto" alt="" />
            <img src={waitWebVec} alt="" />
            <div className="waiting_wrap">
              <div className="mb-40">
                <h1>{data?.title}</h1>
                {parse(`${data && data?.description}`)}
                <p>
                  <Link to={`mailto:${data?.email}`}>{data?.email}</Link>
                </p>
              </div>
              {/* <div className="waitlist_form d-flex align-items-center">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Enter email"
                />
                <button>Join Waitlist</button>
              </div> */}
              <img
                src={data?.banner_image?.upload_image_url}
                className="mx-auto"
                alt=""
              />
            </div>
          </div>
        </>
      )}

      <LogOutModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleModalClose={handleModalClose}
        handleLogout={logout}
        isSubmit={isSubmit}
      />
    </>
  );
}
