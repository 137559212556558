import React from "react";
import Modal from "react-bootstrap/Modal";
import { Star } from "../../../config/common/common";
import { QRCodeSVG } from "qrcode.react";
import copy from "copy-to-clipboard";
import { useToast } from "@chakra-ui/react";

export default function ShareProfile(props) {
  const toast = useToast();
  const textToCopy = `${process.env.REACT_APP_SHARE_LINK}${props?.copyText}`;

  const handleCopy = () => {
    copy(textToCopy);
    toast({
      title: "Copied to clipboard",
      status: "success",
      duration: 1000,
      isClosable: true,
      position: "top-right",
    });
  };

  const getQRCode = () => {
    if (props?.qrValue) {
      return (
        <QRCodeSVG
          size={116}
          value={`${process.env.REACT_APP_SHARE_LINK}${props?.qrValue}`}
        />
      );
    }
  };

  const activityArr =
    props?.guideDetailData?.guideDetails?.guide_activities?.map(
      (item) => item.activity
    );

  return (
    <Modal
      {...props}
      size="lg"
      className="new-modal-design"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-body">
        <div className="d-flex justify-content-between align-items-center mb-4 pb-3 border-bottom">
          <h3 className="f-700 mb-0">{props?.title}</h3>
          <button
            type="button"
            className="btn-close"
            onClick={() => props.onHide()}
          ></button>
        </div>
        <div className="shadow-border p-4 mb-4">
          <div className="d-flex align-items-center profile_section">
            <div className="share_profile_heroimg">
              <img
                src={
                  props?.guideDetailData?.guideDetails?.user_detail
                    ?.profile_picture
                }
                alt="hostImg"
              />
            </div>
            <div className="ps-4">
              <h5 className="mb-1">
                {props?.guideDetailData?.guideDetails?.last_name
                  ? `${props?.guideDetailData?.guideDetails?.name} ${props?.guideDetailData?.guideDetails?.last_name}`
                  : props?.guideDetailData?.guideDetails?.name}
              </h5>
              <p className="mb-1">Hosted Since {props?.hostYear}</p>
              <p className="m-0">
                {activityArr
                  ?.map((item) => item?.title)
                  .join(", ")
                  .replace(/, ([^,]*)$/, " and $1")}
              </p>
              {props?.guideDetailData?.AvgRatings > 0 && (
                <Star stars={props?.guideDetailData?.AvgRatings} />
              )}
            </div>
          </div>

          <div className="bio_section">
            <h6 className="mb-2">Bio</h6>
            <p className="mb-0">
              {props?.guideDetailData?.guideDetails?.user_detail?.bio}
            </p>
          </div>
        </div>
        <div className="QR_section d-flex justify-content-between align-items-end flex-wrap gap-3">
          <div className="d-flex align-items-end flex-wrap gap-3">
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: "116px",
                width: "100%",
              }}
            >
              {getQRCode()}
            </div>
            <p className="p-0 m-0 fw-semibold">
              Scan the QR code to get <br />
              in touch with me.
            </p>
          </div>
          <div className="w-50 popUp_Soical_links">
            {/* <h6>Soical Links</h6> */}
            <div
              onClick={handleCopy}
              className="d-flex py-2 ps-3 justify-content-between align-items-center rounded-3 Soical_links form-control"
            >
              <p className="mb-0">
                {props?.guideDetailData?.guideDetails?.last_name
                  ? `${props?.guideDetailData?.guideDetails?.name} ${props?.guideDetailData?.guideDetails?.last_name}`
                  : props?.guideDetailData?.guideDetails?.name}
              </p>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="18"
                height="18"
                fill="rgba(71,84,103,1)"
              >
                <path d="M6.9998 6V3C6.9998 2.44772 7.44752 2 7.9998 2H19.9998C20.5521 2 20.9998 2.44772 20.9998 3V17C20.9998 17.5523 20.5521 18 19.9998 18H16.9998V20.9991C16.9998 21.5519 16.5499 22 15.993 22H4.00666C3.45059 22 3 21.5554 3 20.9991L3.0026 7.00087C3.0027 6.44811 3.45264 6 4.00942 6H6.9998ZM5.00242 8L5.00019 20H14.9998V8H5.00242ZM8.9998 6H16.9998V16H18.9998V4H8.9998V6Z"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
