import React from "react";
import heroImage from "../../../../../assets/images/hero-char.png";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

export default function JoinLocalitesBanner({ data }) {
  return (
    <section className="join_banner_section primary__bg position-relative">
      <div className="container spacer-y pb-0 position-relative">
        <div className="row justify-content-between align-items-end position-relative g-3">
          <div className="col-lg-7 spacer-y pt-0">
            <p className=" d-inline-block hero-tagline">{data?.subtitle}</p>
            <h1 className="m-0 text-capitalize text-white" data-aos="fade-up">
              {data?.title}
            </h1>
            <div data-aos="fade-up" data-aos-delay="200">
              {parse(`${data?.description}`)}
            </div>
            <Link
              to="/localite/signup"
              // state={{ fromLangingPage: true }}
              className="button-primary"
            >
              Get Started
            </Link>
          </div>
          <div className="col-lg-5">
            <img
              src={data ? data?.banner_image?.upload_image_url : heroImage}
              className="img-fluid hero-char"
              alt=""
            />
          </div>
        </div>
      </div>
    </section>
  );
}
