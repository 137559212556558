import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../../../../../../../config/apiFunctions";
import { reject_itinerary } from "../../../../../../../../config/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useToast } from "@chakra-ui/react";

const RejectModal = (props) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [value, setvalue] = useState(null);
  const [btnDisable, setBtnDisable] = useState(false);
  const access_token = useSelector(
    (state) => state.auth.SignupUser.access_token
  );
  useEffect(() => {
    if (props.show === false) {
      setvalue(null);
    }
  }, [props.show]);
  const handlechnage = (e) => {
    const textarea = document.getElementById("description");
    if (e === "letMeWrite") {
      textarea.removeAttribute("hidden");
    } else {
      textarea.setAttribute("hidden", "");
      setvalue(e);
    }
  };
  const handleinput = (e) => {
    setvalue(e);
  };

  const rejectItinerary = () => {
    setBtnDisable(true);
    const obj = {
      booking_id: props.ids,
      description: value,
    };
    postApi(reject_itinerary, obj, access_token)
      .then((res) => {
        toast({
          title: res.data.message,
          status: "success",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        props.onHide();
        navigate("/dashboard/itinerary");
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal-content p-30">
        <div className="modal_header position-relative">
          <h3 className="text-secondary f-700 mb-2">Reject Itinerary</h3>
          <p className="text-grey mb-2">
            Are you sure you want to cancel your request for this trip?
            <br></br>
            Please let us know why.
          </p>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            onClick={props.onHide}
          ></button>
        </div>
        <div className="modal-body p-0">
          <div>
            <div className="form" action="">
              <div className="d-flex align-items-center justify-content-start my-3">
                <input
                  className="me-2"
                  type="radio"
                  id="reject"
                  value="Going but not booking on Imerzn"
                  name="reject-reason"
                  onChange={(e) => handlechnage(e.target.value)}
                />
                <label
                  className="small-text text-grey-scale f-600 text-capitalize"
                  htmlFor="reject-siesta"
                >
                  Going but not booking on Imerzn
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-start my-3">
                <input
                  className="me-2"
                  type="radio"
                  id="reject"
                  value="Not going, cancel my plan"
                  name="reject-reason"
                  onChange={(e) => handlechnage(e.target.value)}
                />
                <label
                  className="small-text text-grey-scale f-600 text-capitalize"
                  htmlFor="reject-going"
                >
                  Not going, cancel my plan
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-start my-3">
                <input
                  className="me-2"
                  type="radio"
                  id="reject"
                  value="Not satisfied with the current Itinerary"
                  name="reject-reason"
                  onChange={(e) => handlechnage(e.target.value)}
                />
                <label
                  className="small-text text-grey-scale f-600 text-capitalize"
                  htmlFor="reject-satisfy"
                >
                  Not satisfied with the current Itinerary
                </label>
              </div>
              <div className="d-flex align-items-center justify-content-start">
                <input
                  className="me-2"
                  type="radio"
                  id="other-reason"
                  value="letMeWrite"
                  name="reject-reason"
                  onChange={(e) => handlechnage(e.target.value)}
                />
                <label
                  className="small-text text-grey-scale f-600 text-capitalize"
                  htmlFor="other-reason"
                >
                  Other
                </label>
              </div>
              <div className="my-3">
                <div>
                  <div className="input-box mt-3">
                    {/* <div className="time_field w-100 position-relative">
                                                <select className="small-text form-control mb-3" name="reason" id="">
                                                    <option value="Need lower budget.">Need lower budget.</option>
                                                    <option value="Need lower budget.">Need lower budget.</option>
                                                    <option value="Need lower budget.">Need lower budget.</option>
                                                    <option value="Need lower budget.">Need lower budget.</option>
                                                </select>
                                                <i className="small-text text-grey-scale fa-solid fa-angle-down"></i>
                                            </div> */}
                    <textarea
                      hidden
                      className="form-control"
                      name="itinerary-details"
                      id="description"
                      cols="30"
                      rows="4"
                      placeholder=""
                      onChange={(e) => handleinput(e.target.value)}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer border-0 p-0">
          <a className="btn-main text-red" onClick={props.onHide}>
            Not Now
          </a>
          <button
            disabled={btnDisable}
            className="btn-main btn-red"
            onClick={rejectItinerary}
          >
            Reject Itinerary
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RejectModal;
