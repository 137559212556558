import React, { createRef, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { phone } from "phone";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import EmailVerificationPopup from "../../emailverify/EmailVerificationPopup";
import { persistor } from "../../../../redux/store/store";
import { postApi } from "../../../../config/apiFunctions";
import { localiteSignup, signup } from "../../../../config/endpoints";
import { emailRegExp, phoneRegExp } from "../../../../config/common/common";
import { PROFILE_PIC } from "../../../../redux/slices/profilePicture/ProfilePictureSlice";

function SepLocaliteSugnUp() {
  const { state } = useLocation();
  const toast = useToast();
  const dispatch = useDispatch();
  const formOptions = { resolver: yupResolver(Schema) };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const [password, setPassword] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputClick, setInputClick] = useState(false);
  const [btndisable, setBtnDisable] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const [phoneCheckbox, setPhoneCheckbox] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [formEvents, setFormEvents] = useState();
  const [captchaValue, setCaptchaValue] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [passwordError, setpasswordError] = useState();
  const [isSubmit, setSubmit] = useState(false);
  const [show, setShow] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [toggle1, setToggle1] = useState(true);

  const checkhandle = () => {
    setCheckbox(!checkbox);
  };
  const phoneCheckHandle = () => {
    setPhoneCheckbox(!phoneCheckbox);
  };

  // const [getId, setGetId] = useState(state);

  const handleInputChange = (event) => {
    const newValue =
      event.target.value.charAt(0).toUpperCase() + event.target.value.slice(1);
    setInputValue(newValue);
  };

  const hanldeChangepass = (e) => {
    setPassword(e.target.value);
    setpasswordError(e.target.value);
  };

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const hasNumber = /\d/.test(password);
  const hasMinimumLength = password?.length >= 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasSpecialCharacter = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(password);

  const handlepass = () => {
    setToggle(!toggle);
  };
  const handlepass1 = () => {
    setToggle1(!toggle1);
  };

  useEffect(() => {
    setValue("password", password);
  }, []);

  const recaptchaRef = createRef();
  const onChangeCaptcha = (value) => {
    setCaptchaValue(value);
  };

  const [contactDetails, setContatDetails] = useState({
    phone: "",
    isoCode: "+1",
    dialCode: "us",
  });
  const onContactNoChange = (value, country, e, formattedValue) => {
    setPhoneError(phone(`+${value}`).isValid);
    const { dialCode: country_code, countryCode: country_iso_code } = country;
    const _phone = value.slice(country_code.length);
    setContatDetails({
      ...contactDetails,
      phone: _phone,
      isoCode: country_iso_code,
      dialCode: country_code,
    });
    setValue("phone", _phone);
    trigger("phone");
  };

  const onSubmit = (e) => {
    recaptchaRef.current?.reset();
    setCaptchaValue("");
    if (checkbox === false) {
      toast({
        title: "Please agree all the term and conditions.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (phoneCheckbox === false) {
      toast({
        title: "Please agree for receiving SMS text from Imerzn.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else if (!captchaValue) {
      toast({
        title: "CAPTCHA verification failed. Please try again.",
        status: "error",
        duration: 30000,
        isClosable: true,
        position: "top-right",
        onCloseComplete: () => setBtnDisable(false),
      });
    } else {
      setConfirmModal(true);
      setSubmit(true);
      setToggle(true);
      setToggle1(true);
      const obj = {
        // ...e,
        name: e.name,
        last_name: e.last_name || "",
        email: e.email,
        role_name: "GUIDE",
        password: e.password,
        phone: e.phone,
        country_code: `+${contactDetails.dialCode}`,
        country_code_iso: contactDetails.isoCode,
        recaptcha_value: captchaValue,
        invited_login: true,
      };
      setFormEvents(obj);
    }
  };

  const submitData = () => {
    setBtnDisable(true);

    setCaptchaValue("");
    persistor.purge();
    dispatch(PROFILE_PIC([]));
    delete formEvents.cpassword;
    postApi(localiteSignup, formEvents)
      .then((res) => {
        setBtnDisable(false);
        setSubmit(false);
        if (res.status === 200) {
          setBtnDisable(false);
          setShow(true);
          setConfirmModal(false);
          reset();
        }
        reset();
      })
      .catch((err) => {
        setConfirmModal(false);
        // setBtnDisable(false);
        setSubmit(false);
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 30000,
          isClosable: true,
          position: "top-right",
          onCloseComplete: () => setBtnDisable(false),
        });
        reset();
      });
    setValue("phone_condition", false);
    setValue("terms_condition", false);
    setPhoneCheckbox(false);
    setCheckbox(false);
  };

  return (
    <>
      <section className="login-signup-wrapper signup-page">
        <div className="container-fluid px-0 spacer-y">
          <div className="row gy-4 gy-lg-0 gx-0 align-items-center zindex-up">
            <div className="col-lg-6 pe-lg-5 order-2 order-lg-1 spacing-left">
              <h2 className="pb-0 mb-3">Sign Up</h2>
              <p>Sign up to be the localite.</p>
              {/* <p>Sign up to be the localite - Join waiting list to be a localite</p> */}
              {/* <h2 className="pb-0 mb-3">Join Waiting List</h2>
              <p>Join waiting list to be a localite</p> */}
              <div className="tab-teaser">
                <div className="tab-main-box">
                  <div
                    className="tab-box"
                    id="tab-2"
                    style={{ display: "block" }}
                  >
                    <form
                      className="tourist-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="row gy-3 gy-lg-4">
                        <div className="col-lg-6">
                          <label htmlFor="" className=" form-label theme-label">
                            First Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            value={inputValue}
                            // {...register("name")}
                            {...register("name", {
                              onChange: handleInputChange,
                            })}
                            className={`form-control theme-controls ${
                              errors.name ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.name?.message}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="" className="form-label theme-label">
                            Last Name
                          </label>
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Enter last name"
                            {...register("last_name")}
                            className={`form-control theme-controls ${
                              errors.last_name ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.last_name?.message}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label htmlFor="" className="form-label theme-label">
                            Email
                          </label>
                          <input
                            {...register("email")}
                            className={`form-control theme-controls ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            type="email"
                            name="email"
                            placeholder="you@company.com"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label
                            htmlFor=""
                            className="form-label theme-label color__paragraph"
                          >
                            Contact Number
                          </label>
                          <PhoneInput
                            country="us"
                            value={`${contactDetails.dialCode}${contactDetails.phone}`}
                            placeholder="Enter contact number"
                            countryCodeEditable={false}
                            enableSearch={true}
                            onChange={(value, country, e, formattedValue) => {
                              onContactNoChange(
                                value,
                                country,
                                e,
                                formattedValue
                              );
                              setInputClick(true);
                            }}
                            sx={{ width: "100%" }}
                            // isValid={() => (!!errors.phone ? "" : true)}
                            isValid={(value) => {
                              if (inputClick) {
                                return phone(`+${value}`).isValid
                                  ? true
                                  : false;
                              } else {
                                return true;
                              }
                            }}
                          />

                          <input
                            type="hidden"
                            name="phone"
                            minLength="10"
                            maxLength="15"
                            placeholder="Enter your contact number"
                            {...register("phone")}
                          />
                          {/* <div className="invalid-feedback">
              {errors.phone?.message}
            </div> */}
                          {inputClick && !phoneError && (
                            <div
                              className="text-danger mt-1"
                              sx={{ ml: "1px" }}
                              error={true}
                              style={{ fontSize: ".875em" }}
                            >
                              Enter a valid Phone Number
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <div className="checkbox-wrap">
                            <label className="checkbox-container">
                              <input
                                name="phone_condition"
                                type="checkbox"
                                {...register("phone_condition", {
                                  onChange: phoneCheckHandle,
                                })}
                                checked={phoneCheckbox}
                              />
                              <div className="checkmark"></div>
                            </label>
                            <p className="mb-0">
                              I consent to receiving SMS text messages from
                              Imerzn.
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label htmlFor="" className="form-label theme-label">
                            Password
                          </label>
                          <div className="input-icon-grp">
                            <input
                              type={toggle === true ? "password" : "text"}
                              name="password"
                              defaultValue={password}
                              //  className="form-control theme-controls"
                              placeholder="8 character or more"
                              // {...register("password")}
                              {...register("password", {
                                onChange: hanldeChangepass,
                              })}
                              className={`form-control theme-controls ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              onFocus={togglePopup}
                              onBlur={togglePopup}
                            />
                            <div className="invalid-feedback">
                              {errors.password?.message}
                            </div>
                            <i
                              className={
                                toggle === true
                                  ? "fa-light  fa-eye-slash show-pass"
                                  : "fa-light  fa-eye show-pass"
                              }
                              onClick={handlepass}
                            ></i>
                          </div>
                          {showPopup && (
                            <div className="popup mt-2">
                              <h6>Password Requirements:</h6>
                              <ul>
                                <li
                                  className={
                                    hasMinimumLength
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  At least 8 characters
                                </li>
                                <li
                                  className={
                                    hasUppercase
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  At least one uppercase letter
                                </li>
                                <li
                                  className={
                                    hasLowercase
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  At least one lowercase letter
                                </li>
                                <li
                                  className={
                                    hasNumber ? "text-success" : "text-danger"
                                  }
                                >
                                  At least one number
                                </li>
                                <li
                                  className={
                                    hasSpecialCharacter
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  At least one special character
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-12">
                          <label htmlFor="" className="form-label theme-label">
                            Confirm Password
                          </label>
                          <div className="input-icon-grp">
                            <input
                              type={toggle1 === true ? "password" : "text"}
                              name="cpassword"
                              placeholder="8 character or more"
                              {...register("cpassword")}
                              className={`form-control theme-controls ${
                                errors.cpassword ? "is-invalid" : ""
                              }`}
                            />
                            <i
                              className={
                                toggle1 === true
                                  ? "fa-light  fa-eye-slash show-pass"
                                  : "fa-light  fa-eye show-pass"
                              }
                              onClick={handlepass1}
                            ></i>
                            <div className="invalid-feedback">
                              {errors.cpassword?.message}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="checkbox-wrap">
                            <label className="checkbox-container">
                              <input
                                name="terms_condition"
                                type="checkbox"
                                checked={checkbox}
                                {...register("terms_condition", {
                                  onChange: checkhandle,
                                })}
                              />
                              <div className="checkmark"></div>
                            </label>
                            <p className="mb-0">
                              By signing up, you agree to our{" "}
                              <Link to="/terms" className="primary__color">
                                Terms of Service
                              </Link>{" "}
                              and{" "}
                              <Link to="/privacy" className="primary__color">
                                Privacy Notice
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                            onChange={onChangeCaptcha}
                          />
                        </div>
                        <div className="col-lg-12">
                          {btndisable === true && isSubmit === true ? (
                            <button
                              type="submit"
                              disabled={btndisable}
                              className="button-primary w-100 next-step create-profile-btn"
                            >
                              <ThreeDots
                                height="20"
                                width="80"
                                radius="9"
                                color="white"
                                ariaLabel="three-dots-loading"
                                wrapperStyle={{}}
                                wrapperClassName=""
                                visible={true}
                              />
                            </button>
                          ) : (
                            <input
                              type="submit"
                              disabled={btndisable}
                              value="Next step"
                              className="button-primary w-100 next-step create-profile-btn"
                            />
                          )}
                          <EmailVerificationPopup
                            show={show}
                            onHide={() => setShow(false)}
                          />
                        </div>
                        <div className="col-lg-12">
                          <p className="text-sm mb-0 text-center">
                            Already have an account.{" "}
                            <Link
                              to="/login"
                              className="primary__color fw-bold"
                            >
                              Log In
                            </Link>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div className="aside-image"></div>
            </div>
          </div>
        </div>
        <div className="gradient-overlay hero"></div>
      </section>

      <Modal
        show={confirmModal}
        onHide={() => setConfirmModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="p-0"
      >
        <div
          className={
            confirmModal === true
              ? "p-5 text-center"
              : "modal fade p-5 text-center"
          }
          id="cancel_trip"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal_header position-relative">
            <h5 className="text-grey-500 mb-2">
              Please confirm you want to sign up as a localite with Imerzn
            </h5>
          </div>
          <div className="modal-footer justify-content-center border-0 p-0 pt-3">
            <a
              className="btn-main btn-red min-w "
              onClick={() => setConfirmModal(false)}
            >
              Cancel
            </a>
            <button
              className="btn-main btn-primary min-w button-primary"
              type="submit"
              onClick={submitData}
            >
              {btndisable === true ? (
                <ThreeDots
                  height="20"
                  width="80"
                  radius="9"
                  color="white"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SepLocaliteSugnUp;

const Schema = Yup.object().shape({
  name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .max(20, "Please enter 20 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only")
    .matches(/^[A-Z]/, "First letter should be uppercase."),
  last_name: Yup.string()
    .max(20, "Please enter 20 characters only")
    .matches(/^[A-Za-z\s’']*$/, "Please enter letters only"),
  email: Yup.string()
    .required("Email is required")
    .matches(emailRegExp, "Please enter valid email address"),
  password: Yup.string().required("Password is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Please enter valid mobile number"),
  // .min(8, "Password must be atleast 8 characters long")
  // .matches(
  //   /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
  //   "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  // )
  role_name: Yup.string(),
  terms_condition: Yup.boolean().required(),
  phone_condition: Yup.boolean().required(),
  cpassword: Yup.string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 characters long")
    .matches(
      /^(?=.*[!@#$%^&*()\-_=+{};:,<.>])(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
      "Password must contain at least 8 characters, one uppercase, one number and one special case character"
    )
    .oneOf([Yup.ref("password")], "Passwords does not match"),
});
