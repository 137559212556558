import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Star } from "../../../../../config/common/common";
import { Modal } from "react-bootstrap";
import LoginModal from "../../../../popup/loginPopUp/LoginModal";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { ThreeDots } from "react-loader-spinner";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RxCalendar } from "react-icons/rx";
import { BsClockHistory } from "react-icons/bs";
import { postApi } from "../../../../../config/apiFunctions";
import { traveler_book_experience } from "../../../../../config/endpoints";
import { useToast } from "@chakra-ui/react";

const Schema = Yup.object().shape({
  first_name: Yup.string()
    .required("First name is required")
    .min(2, "Too Short!")
    .trim(" "),
  last_name: Yup.string()
    .required("Last name is required")
    .min(2, "Too Short!")
    .trim(" "),
  location: Yup.string(),
  notes: Yup.string()
    .required("Notes is required")
    .max(180, "Please enter 180 characters only"),
});

export default function YourHost({ postData }) {
  const formOptions = {
    resolver: yupResolver(Schema),
  };
  const { register, handleSubmit, formState, reset, setValue, trigger } =
    useForm(formOptions);
  const { errors } = formState;
  const toast = useToast();
  const navigate = useNavigate()

  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [btndisable, setBtndisable] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date());

  const User = useSelector((state) => state.auth.SignupUser);

  const number = parseFloat(postData?.user?.avgRating) || 0;
  const rating = number?.toFixed(2);

  const hostYear = moment()
    .subtract(postData?.user?.user_detail?.host_since_years, "years")
    .format("YYYY");

  const handleOpenModal = () => {
    if (User?.access_token) {
      setShowBookingModal(true);
    } else {
      setShowLoginModal(true);
    }
  };

  const startTimehandler = (date) => {
    setStartTime(date);
  };

  const formSubmit = (data) => {
    const s_date = moment(startDate).format("L");
    const s_time = moment(startTime).format("LT");
    const payload = {
      traveller_id: User?.id,
      localite_id: postData?.user?.id,
      experience_id: postData?.id,
      price: postData?.price,
      start_date: s_date,
      start_time: s_time,
      notes: data.notes,
      location: data.location,
      first_name: data.first_name,
      last_name: data.last_name
    };
    postApi(traveler_book_experience, payload, User?.access_token)
      .then((res) => {
        if (res.status === 200) {
          toast({
            title: res.data.message,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
          reset();
          navigate(`/dashboard/my-bookings`);
        }
      })
      .catch((err) => {
        toast({
          title: err.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
  };

  useEffect(() => {
    if (User) {
      setValue("first_name", User?.name);
      setValue("last_name", User?.last_name);
    }
    if (postData) {
      setValue("location", postData?.location);
    }
  }, [User, postData, setValue]);

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="hero_img">
          <img
            src={postData?.user?.user_detail?.profile_picture}
            alt="heroImg"
          />
        </div>
        <div className="hero_content ps-4">
          <Link to={`/guide_detailed_page/${postData?.user?.id}`}>
            <h6 className="m-0">Meet your host, {postData?.user?.name}</h6>
            <p>Host since {hostYear}</p>

            {rating > 0 && <Star stars={rating} />}
          </Link>
        </div>
      </div>
      <div className="pt-5 col-md-7">
        <p>{postData?.user?.user_detail?.bio}</p>
        <button onClick={handleOpenModal} className="button-primary mb-4">
          Join The Experience
        </button>
      </div>

      <LoginModal
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      />

      <Modal
        show={showBookingModal}
        onHide={() => setShowBookingModal(false)}
        size="lg"
        className="modal-width-custom"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-content p-4">
          <div className="modal-header position-relative">
            <div class="">
              <h3 className="f-700 mb-2">Request Info</h3>
              <p className="p-0 m-0">
                Your Dream Destination Awaits: Join Your Adventure Today!
              </p>
            </div>

            <button
              type="button"
              className="btn-close"
              onClick={() => setShowBookingModal(false)}
            ></button>
          </div>
          <div className="modal-body">
            <form className="traveler-form" onSubmit={handleSubmit(formSubmit)}>
              <div className="row gy-3 gy-lg-4">
                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="first_name"
                    disabled
                    placeholder="First name"
                    {...register("first_name")}
                    className={`form-control theme-controls ${
                      errors.first_name ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.first_name?.message}
                  </div>
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last_name"
                    disabled
                    placeholder="First name"
                    {...register("last_name")}
                    className={`form-control theme-controls ${
                      errors.last_name ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.last_name?.message}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Location
                  </label>
                  <input
                    type="text"
                    name="location"
                    disabled
                    placeholder="Location"
                    {...register("location")}
                    className={`form-control theme-controls ${
                      errors.location ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.location?.message}
                  </div>
                </div>

                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Start Date
                  </label>
                  <div className="input-wrapper position-relative">
                    <DatePicker
                      className="form-control theme-controls mw-100"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      minDate={new Date()}
                    />
                    <RxCalendar
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#6c757d",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Start Time
                  </label>

                  <div className="input-wrapper position-relative">
                    <DatePicker
                      className="form-control theme-controls mw-100"
                      selected={startTime}
                      onChange={(date) => startTimehandler(date)}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                      dateFormat="h:mm aa"
                    />
                    <BsClockHistory
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        pointerEvents: "none",
                        color: "#6c757d",
                        fontSize: "1.3rem",
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <label
                    htmlFor=""
                    className="form-label theme-label color__paragraph"
                  >
                    Notes
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    placeholder="Give description (100 to 180 words)"
                    {...register("notes")}
                    className={`form-control theme-controls ${
                      errors.notes ? "is-invalid" : ""
                    }`}
                  />
                  <div className="invalid-feedback">
                    {errors.notes?.message}
                  </div>
                </div>

                <div className="col-lg-12 text-center pt-3">
                  {btndisable === true ? (
                    <button
                      type="submit"
                      disabled={btndisable}
                      className="button-primary email-send-trigger w-100"
                    >
                      <div className="d-flex justify-content-center">
                        <ThreeDots
                          height="20"
                          width="80"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={btndisable}
                      className="button-primary email-send-trigger w-100"
                    >
                      Save
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
}
