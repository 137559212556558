import React from 'react'
import Modal from "react-bootstrap/Modal";

function AvailabilityModal(props) {
  return (
    <Modal
    {...props}
    size="lg"
    className="new-modal-design"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <div className="modal-body">
      <div className="d-flex justify-content-between align-items-center mb-4 pb-3 border-bottom">
        <h4 className="f-700 mb-0">Add Availability</h4>
        <button
          type="button"
          className="btn-close"
          onClick={() => props.onHide()}
        ></button>
      </div>
      <div className="shadow-border p-4 mb-4">
        <div className="d-flex align-items-center profile_section">
          
          <div className="ps-4">
            <h5 className="mb-1">
            AvailabilityModal
            </h5>
          </div>
        </div>

        <div className="bio_section">
          <p className="mb-0">
          AvailabilityModal
          </p>
        </div>
      </div>
    </div>
  </Modal>
  )
}

export default AvailabilityModal